<template>
  <div>
    <b-card v-if='!isDataLoaded'>
      <div class='text-center text-success my-2'>
        <b-spinner class='align-middle' />
      </div>
    </b-card>

    <div v-else>
      <MainInfoRealEstate
        :keys="keys"
        :title="$t('property_information')"
      />
      <b-card v-if="details && details.images">
        <h3 class="mb-2">
          {{ $t('images') }}
        </h3>
        <div class="real-estate-images">

          <div
            v-for="(image,index) in details.images"
            :key="image"
          >
            <div class="position-relative">
              <div class="box-img">
                <div class="ribbon-wrapper">
                  <div v-if="index === 0" class="ribbon bg-primary"> {{ $t('thumbnail') }}</div>
                </div>

                  <img
                    :src="image.original_url"
                    alt="Image"
                    class="w-100 custom-object-fit"
                  >
              </div>
            </div>
          </div>
        </div>
        <!--         <div>-->
        <!--           <h3 class="mb-2">-->
        <!--             {{ $t('thumbnail') }}-->
        <!--           </h3>-->
        <!--           <div class="box-img">-->
        <!--             <img-->
        <!--               :src="details.thumbnail"-->
        <!--               alt="Image"-->
        <!--               class="w-100 custom-object-fit"-->
        <!--             >-->
        <!--           </div>-->
        <!--         </div>-->
      </b-card>
    </div>
  </div>
</template>

<script>
import MainInfoRealEstate from '@/components/MainInfoRealEstate.vue'

export default {
  components: {
    MainInfoRealEstate,
  },
  data() {
    return {
      details: null,
      keys: null,
      isDataLoaded: false,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data

          this.keys = _.pick(this.details, [
            'title',
            'region',
            'city',
            'district',
            'the_sub_type_of_ad',
            'type',
            'advertiser_name',
            'advertiser_character',
            'advertiser_phone',
            'selling_price',
            'published_at',
            'status_value',
          ])

          this.isDataLoaded = true
        })
    } else {
      this.isDataLoaded = true
    }
  },
}
</script>
